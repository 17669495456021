import axios from 'axios';

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const TOFA_SEND_EMAIL = 'https://us-central1-tofa-309321.cloudfunctions.net/send--email'

async function sendEmail(details) {
    if (process.env.NODE_ENV==="development"){
        await sleep(1000);
        return {
            data: {
                success: true,
                data: {}
            }
        }
    } else {
        const params = {
            to: details.to,
            subject: details.subject,
            html: details.html
        }
        const api = TOFA_SEND_EMAIL
        return axios.post(api, params)
    }
    
}


export { sendEmail };
