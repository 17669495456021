import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ProductLinkDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (cmd) => {
    setOpen(false);
    props.handleProductLinkClose(cmd);
  };

  return (
    <div>
      <Button
        id="product-link-dialog"
        onClick={handleClickOpen}
        style={{display: "none"}}
      >
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Paste link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tofa will try to extract the product details
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="extract-product-link"
            label="Product link"
            placeholder="https://www.amazon.com/gp/product/B01AWLHRSO"
            type="url"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('cancel')} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose('extract')} color="primary">
            Extract
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
