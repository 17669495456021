import React from "react"
import ReactDOM from "react-dom"
import { Router, Route } from 'react-router'
import { createBrowserHistory } from 'history'
import HomePage from './components/home/home-page'
import AboutPage from './components/about'
import SignUp from './components/signup'
import SignIn from './components/signin'
import ProfilePage from './components/profile-page'
import FriendsPage from './components/friends-page'
import WishListPage from './components/wishlist-page'
import ForgotPasswordPage from './components/forgot-password-page'

const history = createBrowserHistory();

ReactDOM.render(
	(
	   <Router history = {history}>
	      <Route path = "/" component = {HomePage}>
	         <Route exact path="/" component = {HomePage} />
	         <Route path = "/home" component = {HomePage} />
	         <Route path = "/about" component = {AboutPage} />
	         <Route path = "/signup" component = {SignUp} />
	         <Route path = "/signin" component = {SignIn} />
	         <Route path = "/profile" component = {ProfilePage} />
	         <Route path = "/friends" component = {FriendsPage} />
	         <Route path = "/wishlist" component = {WishListPage} />
	         <Route path = "/forgot-password" component = {ForgotPasswordPage} />
	      </Route>
	   </Router>
	),
	document.getElementById('root')
)
