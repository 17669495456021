import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Swal from 'sweetalert2'
import {isMobile} from '../../utils'
import {sendEmail} from '../../email-utils'
import {firebase} from '../../firebase'
import $ from 'jquery'

export default function ProductResult(props) { 

  const handleTakeMe = (link) => {
    if (!link){
      Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'This item does not have a product link'
          });
    } else
      window.open(link, '_blank')
  };

  const handleNotify = (targetPerson, item, itemID) => {
    console.log('notify: ', item, itemID);

    Swal.fire({
        title: 'Let '+targetPerson.first_name+' know that you are planning to gift this to them?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Notify`,
        icon: 'question',
        customClass: {
          container: 'notify-swal'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          // send email
          let link = window.origin+'/wishlist'
          const html = `
            <div>
              <p>Hi `+targetPerson.first_name+`, </p>
              <p>Someone is planning to gift you something from your Tofa wishlist 🤫. See who</p>
              <a href="`+link+`">`+link+`</a>
            </div>
          `
          sendEmail({
            to: targetPerson.email,
            subject: "Someone gifted 🎁 you on Tofa",
            html: html
          }).then((res)=>{
            console.log('Email sent: ', res)
          })

          const notificationText = `Hooray🎁! `+props.user.first_name+" "+props.user.last_name+` is 
          planning to gift you `+item.name.slice(0,30)+`🥳`
          const newNotification = {
            text: notificationText,
            from_profile_picture: props.user.profile_picture,
            timestamp: Date.now(),
            seen: false,
            event: "GIFT",
            itemID: itemID
          }

          // push notification to real time db
          props.displayCircularProgress()
          const notificationListRef = firebase.database().ref('users/'+targetPerson.uid+'/notifications');
          const newNotificationRef = notificationListRef.push();
          newNotificationRef.set(newNotification).then(()=>{
            props.hideCircularProgress()
            Swal.fire({
              icon: 'success',
              title: 'Notified '+targetPerson.first_name+'!',
              customClass: {
                container: 'notify-swal-success'
              }
            })
            $('.notify-swal-success').css("z-index",'999999');
          }).catch(function(err){
                props.hideCircularProgress()
                Swal.fire({
                  icon: 'error',
                  title: 'Oops something went wrong',
                  text: err,
                  customClass: {
                    container: 'notify-swal-error'
                  }
                })
                $('.notify-swal-error').css("z-index",'999999');
          });
        } else if (result.isDismissed) {
        }
      })
    $('.notify-swal').css("z-index",'999999');
  }

  let itemTextStyles = {}
  let doneIconStyles = {marginTop: "18px", visibility: "hidden"}
  if (props.item.done){
    itemTextStyles = {textDecoration: "line-through"}
    doneIconStyles['visibility'] = "visible"
  }

  let cardStyle = {
    backgroundColor: "white",
    marginBottom: '20px'
  }
  let itemTextVariant = "body";
  let itemTitleCardStyle = {
    float: "left",
    marginTop: "6px",
    marginLeft: "-5px",
  }
  let ItemPriceCardStyle = {float: "right", marginTop: "6px"}
  let GoButtonStyle = {marginLeft: "316px"}
  let ImgStyle = {
    width: "60px",
    height: "60px",
    float: "left",
    margin: "10px",
    marginRight: "0px"
  }
  if (isMobile()){
    cardStyle['width'] = '262px'
    cardStyle['marginLeft'] = '-28px'

    itemTextVariant = "caption"
    itemTitleCardStyle['width'] = '190x'
    itemTitleCardStyle['marginTop'] = '0px'
    itemTitleCardStyle['marginLeft'] = '8px'
    itemTitleCardStyle['padding'] = '0'

    ItemPriceCardStyle['marginTop'] = '0px'
    ItemPriceCardStyle['padding'] = '0'
    ItemPriceCardStyle['marginRight'] = '8px'

    GoButtonStyle['marginLeft'] = "0px"
    ImgStyle['marginLeft'] = '103px'
    ImgStyle['marginRight'] = '10px'

  }
  return (  
    <Card style={cardStyle}>
      <div id="ItemDetails">
        <CardActionArea>
          <Avatar 
              src={props.item.image_link || ""}
              style={ImgStyle}>IMG
            </Avatar>

          <CardContent id="itemTitleCard" style={itemTitleCardStyle}>
            <Typography gutterBottom variant={itemTextVariant} style={itemTextStyles}>
              {props.item.name.slice(0,30)+' ...'}
            </Typography>
          </CardContent>

          <CardContent style={ItemPriceCardStyle}>
            <Typography gutterBottom variant={itemTextVariant}>
              ${props.item.price || '-'}
            </Typography>
          </CardContent>
        </CardActionArea>
      </div>

      <CardActions>
        <Button
          size="small"
          color="primary"
          style={{}}
          onClick={() => handleNotify(props.targetPerson, props.item, props.itemID)}>
            Notify
        </Button>

        <Button
          size="small"
          color="primary"
          style={GoButtonStyle}
          onClick={() => handleTakeMe(props.item.link)}>
            Go to product
        </Button>
      </CardActions>
    </Card>
  );
}
