import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Swal from 'sweetalert2'
import {isMobile} from '../../utils'

export default function ProductItem(props) {

  const handleOpenItem = (link) => {
    if (!link){
      Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'This item does not have a product link'
          });
    } else
      window.open(link, '_blank')
  };

  const handleMarkAsReceived = () => {
    console.log("handleMarkAsReceived: ", props)
    props.handleMarkAsReceived(props.itemID);
    
  };

  let itemTextStyles = {}
  let receivedButtonStyles = {}
  let doneIconStyles = {marginTop: "18px", visibility: "hidden"}
  if (props.item.done){
    itemTextStyles = {textDecoration: "line-through"}
    receivedButtonStyles = {visibility: "hidden"}
    doneIconStyles['visibility'] = "visible"
  }

  let cardStyle = {
    backgroundColor: "white",
    transition: "background-color 0.5s ease"
  }
  let itemTextVariant = "h6";
  let itemTitleCardStyle = {
    float: "left",
    marginTop: "6px",
    marginLeft: "-5px",
  }
  let ItemPriceCardStyle = {float: "right", marginTop: "6px"}
  let ArchiveButtonStyle = {float: "right"}
  let ImgStyle = {
    width: "60px",
    height: "60px",
    float: "left",
    margin: "10px",
    marginRight: "0px"
  }
  let ReceivedText = 'Cross off'
  if (isMobile()){
    cardStyle['width'] = '330px'
    cardStyle['marginLeft'] = '-37px'
    itemTextVariant = "caption"
    itemTitleCardStyle['width'] = '125x'
    ItemPriceCardStyle['marginTop'] = '13px'
    ImgStyle['marginLeft'] = '135px'
    ImgStyle['marginRight'] = '10px'
    ReceivedText = 'Received'
  }
  return (  
    <Card id={"item-details-card-"+props.itemID} style={cardStyle}>
      <div id="ItemDetails">
        <CardActionArea onClick={() => handleOpenItem(props.item.link)}>

          <Avatar 
              src={props.item.image_link || ""}
              style={ImgStyle}>IMG
            </Avatar>

          <CardContent id="itemTitleCard" style={itemTitleCardStyle}>
            <Typography gutterBottom variant={itemTextVariant} style={itemTextStyles}>
              {props.item.name.slice(0,30)+' ...'}
            </Typography>
          </CardContent>

          <DoneIcon
          color="primary"
          fontSize="large"
          style={doneIconStyles}
          />

          <CardContent style={ItemPriceCardStyle}>
            <Typography gutterBottom variant={itemTextVariant} component="h3">
              ${props.item.price || '-'}
            </Typography>
          </CardContent>

        </CardActionArea>
      </div>

      <CardActions style={{display: "block"}}>

        <Button
        size="small"
        color="primary"
        style={receivedButtonStyles}
        onClick={() => handleMarkAsReceived()}>
          {ReceivedText}
          <DoneOutlineIcon style={{
            marginLeft: "5px"
          }} />
        </Button>

        <Button
        size="small"
        color="primary"
        style={ArchiveButtonStyle}
        onClick={() => props.handleArchive(props.itemID)}>
          Delete
          <DeleteIcon/>
        </Button>

      </CardActions>
    </Card>
  );
}
