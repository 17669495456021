import React from 'react'
import HomePageHeader from './home/home-page-header'
import {isMobile} from '../utils'

class AboutPage extends React.Component{
	render(){
		document.title = 'About Tofa'

		let img_height = "32em"
		if (isMobile()){
			img_height = "15em"
		}
		return (
		    <div className="AboutPage">
		    	<HomePageHeader />
		    	<div className="AboutImage" style={{
		    		width: "60%",
		    		marginLeft: "20%",
		    		marginTop: "50px"
		    	}}>
		    		<img alt="About Tofa" src="aboutme2.jpg" style={{
		    			height: img_height,
		    			width: "100%",
		    			backgroundColor: "grey",
		    		}}></img>
		    	</div>

		    	<div className="AboutSection" style={{
		    		marginTop: "40px",
		    		marginLeft: "20%",
		    		width: "60%"
		    		}}>
		    		<h2 style={{textAlign: "center"}}>About Tofa</h2>

		    		<p style={{textAlign: "center"}}>
		    		I am <a href="https://www.anshilbhansali.com/" target="_blank" rel="noopener noreferrer">Anshil Bhansali</a>, and Tofa is my baby. 
		    		I created this because I saw there was an inefficiency between gifting presents between loved ones.
		    		</p>

		    		<p style={{textAlign: "center"}}>
		    		The problem I am trying to solve here is that often we like to gift our loved ones presents, 
		    		but we aren't always sure what we want to gift, but we are sure how much we want to spend on the person.
		    		</p>

		    		<p style={{textAlign: "center"}}>
		    		Tofa strives to reinvent the 'gift registry' concept by allowing people to create their wishlists easily
		    		by simply pasting product links, or using the chrome plugin while online shopping. Users can maintain their Tofa wishlists 
		    		by categorizing wishes, configuring visibility settings, and allowing users to cross off wishes.
		    		</p>

		    		<p style={{textAlign: "center"}}>
		    		Tofa allows you to discover your friends, and find their closest preference with respect to your budget.
		    		Tofa also allows users to purchase gifts directly from the site, and have them delivered to their loved ones.
		    		</p>

		    		<p style={{textAlign: "center"}}>
		    		Maintain your Tofa wishlist and invite your friends! Its free :)
		    		</p>
		    	</div>
		    </div>
		  )
	}
}

export default AboutPage