import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import $ from 'jquery';
import {validEmail, validPassword} from '../utils'
import {firebase, index} from '../firebase'
import Swal from 'sweetalert2'
import {writeUserData} from '../db-utils'
import {getUser} from '../db-utils'
import {sendEmail} from '../email-utils'


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Tofa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignUp extends React.Component {
  constructor (props) {
        super(props)
        this.state = {
            firstNameError: false,
            emailError: false,
            lastNameError: false,
            passwordError: false,
            user: null

        }
    }

  handleEmail = () => {
    if (!validEmail($("#email").val())){
      this.setState({
          emailError: true
      })
    } else {
      this.setState({
          emailError: false
      })
    }
  };

  handleName = (elementID, error) => {
    if (!$("#"+elementID).val()){
      this.setState({
          [error]: true
      })
    } else {
      this.setState({
          [error]: false
      })
    }
  };

  handlePassword = () => {
    if (!validPassword($("#password").val())){
      this.setState({
          passwordError: true
      })
    } else {
      this.setState({
          passwordError: false
      })
    }
  };

  handleSignUp = (e) => {    
    const firstName = $("#firstName").val();
    const lastName = $("#lastName").val();
    const email = $("#email").val();
    const password = $("#password").val();

    // validate fields
    if (!validEmail(email)){
      console.log('invalid email cant sign iup');
      return
    }
    if (!firstName){
      console.log('no firstName cant sign iup');
      return
    }
    if (!lastName){
      console.log('no lastName cant sign iup');
      return
    }
    if (!validPassword(password)){
      console.log('invalid pwd cant sign iup');
      return
    }

    // Sign up
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in 
      var user = userCredential.user;
      const notifications = [
      {
        text: "Step 2) Add your first item to your Tofa wishlist 🎉",
        seen: false,
        timestamp: Date.now(),
        from_profile_picture: "logo.png",
      },
      {
        text: "Step 1) Upload a profile photo 🌞",
        seen: false,
        timestamp: Date.now(),
        from_profile_picture: "logo.png",
      },
      {
        text: "Welcome to Tofa 🤩",
        seen: false,
        timestamp: Date.now(),
        from_profile_picture: "logo.png",
      }]
      // create user
      writeUserData(user.uid, firstName, lastName, user.email, '', notifications).then(function(val){
        console.log('wrote user data', val);
        let user = firebase.auth().currentUser;
        user.sendEmailVerification().then(() => {
          // verification email sent

          // index user
          const record = {
            objectID: user.uid,
            first_name: firstName,
            last_name: lastName,
            email: user.email
          }
          index.saveObject(record)

          // send welcome email
          const html = `
            <div>
              <p>Hello `+firstName+`, </p>
              <h3>Welcome to Tofa 🎁</h3>
              <p>I built Tofa so that friends can gift each meaningful gifts without second guessing.
                Discover what your friends want and see what fits your budget!
              </p>

        <p> You can easily start adding items to you Tofa wishlist by pasting the product link and Tofa will extract the details 😄 </p>
              <p>Start building your Tofa wishlist here <a href="https://www.tofawish.com/wishlist">https://www.tofawish.com/wishlist</a></p>
              <p> </p>
          <p>Regards, </p>
              <p>Anshil Bhansali</p>
              <p>Founder, Tofa </p>
              <a href="https://www.anshilbhansali.com/">https://www.anshilbhansali.com/</a>
            </div>
          `
          sendEmail({
            to: user.email,
            subject: "Welcome to Tofa",
            html: html
          }).then((res)=>{
            console.log('Email sent: ', res)

          })

          .then(() => {
            console.log('Firebase object indexed in Algolia', record.objectID);
            window.open('/wishlist', '_self');
          })
          .catch(error => {
            console.error('Error when indexing user into Algolia', error);
          });
        })
      });
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
        footer: errorCode
      })
    });
  };

  render(){
    const { classes } = this.props;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        getUser(user.uid).on('value', snapshot => {
        const founduser = snapshot.val();
        founduser.uid = user.uid;
        if (!this.state.user){
          this.setState({
            user: founduser,
            items: founduser.items || []
          })
        }
        //window.open('/wishlist', '_self');
        });
      } else {
        
      }
    }.bind(this));

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={this.state.firstNameError}
                  onChange={() => {this.handleName('firstName', 'firstNameError')}}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={this.state.lastNameError}
                  onChange={() => {this.handleName('lastName', 'lastNameError')}}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={this.state.emailError}
                  onChange={this.handleEmail}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={this.state.passwordError}
                  onChange={this.handlePassword}
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  helperText="Must be 8 characters long and contain atleast one number"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={this.handleSignUp}
              style={{
                backgroundColor: "#007bff",
                color: "white"
              }}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2" style={{
                  color: "#007bff"
                }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(SignUp);

