import React from 'react'
import AppHeader from './app-header'
import TextField from '@material-ui/core/TextField';
import AddressInput from 'material-ui-address-input'; //https://www.npmjs.com/package/material-ui-address-input
import {firebase, index} from '../firebase'
import $ from 'jquery';
import Swal from 'sweetalert2'
import IconButton from '@material-ui/core/IconButton';
import {getUser, updateUser} from '../db-utils'
import {isMobile} from '../utils'
import {uploadFile, downloadFile} from '../blob-utils'

class ProfilePage extends React.Component{
	constructor (props) {
        super(props)
        this.state = {
            address: 0,
            addresses: [],
            user: null,
            profile_picture: "avatar.png"
        }
    }
 
    handleAddAddress = address => {
    	const newAddresses = [...this.state.addresses, address]
        this.setState({
            addresses: newAddresses
        });


        if (this.state.user.uid && newAddresses.length) {
        	updateUser(this.state.user.uid, {addresses: newAddresses}).then().catch(function(err){
	        	Swal.fire({
			        icon: 'error',
			        title: 'Oops...',
			        text: err,
			        footer: "Something went wrong adding address"
			      });
			});
        }

    };
 
    handleChangeAddress = addressIndex => {
    	if (!addressIndex)
    		addressIndex = 0;
		this.setState({
            address: addressIndex
        })


        if (this.state.user.uid && addressIndex>=0) {
        	updateUser(this.state.user.uid, {addressIndex: addressIndex}).then().catch(function(err){
	        	Swal.fire({
			        icon: 'error',
			        title: 'Oops...',
			        text: err,
			        footer: "Something went wrong updating address"
			      });
			});
        }

    };

    handleBirthday = () => {
    	let birthdate = $("#birthdate").val();
    	let pieces = birthdate.split('-')
    	if (pieces.length===3){
    		let [year, month, day] = pieces;
    		if ((parseInt(month)>=1) || (parseInt(month)<=12)){
    			if ((parseInt(day)>=1) || (parseInt(day)<32)){
    				if (year){
    					updateUser(this.state.user.uid, {birthday: birthdate}).then().catch(function(err){
    						Swal.fire({
						        icon: 'error',
						        title: 'Oops...',
						        text: err,
						        footer: "Something went wrong saving birthday"
						      });
    					});
    				}
    			}
    		}
    	}
    };

    handleNameChange = () => {
    	const first_name = $("#firstName").val();
    	const last_name = $("#lastName").val();

		updateUser(this.state.user.uid, {first_name: first_name, last_name: last_name}).then(()=>{
			let num_items = 0
			if (this.state.user && this.state.user.items){
				num_items = Object.keys(this.state.user.items).length
			}
			const record = {
	            objectID: this.state.user.uid,
	            first_name: first_name,
	            last_name: last_name,
	            email: this.state.user.email,
	            num_items: num_items
	          }
	          index.saveObject(record)
	          .then(() => {
	            console.log('Firebase object indexed in Algolia', record.objectID);
	          })

		}).catch(function(err){
			Swal.fire({
		        icon: 'error',
		        title: 'Oops...',
		        text: err,
		        footer: "Something went wrong name"
		      });
		});
    	

    };

    handleProfileClick = () => {
    	$("#profile-picture-file").click();
    };

    handleProfilePicChange = () => {
    	const selectedFile = document.getElementById('profile-picture-file').files[0];
		this.readFile(selectedFile)
    };

    readFile = (file) => {
	  	const reader = new FileReader();
	  	reader.addEventListener('load', (event) => {
	    	const result = event.target.result;
			this.setState({
	            profile_picture: result
	        })

			// WRITE TO BLOB STORAGE
			const profile_pic_path = 'profile_pictures/'+this.state.user.first_name+this.state.user.last_name+this.state.user.uid
			uploadFile(profile_pic_path, result)
	  	});

	  	reader.addEventListener('progress', (event) => {
	    	if (event.loaded && event.total) {
	      	const percent = (event.loaded / event.total) * 100;
	      	console.log(`Progress: ${Math.round(percent)}`);
	    	}
	  	});

	  	reader.readAsDataURL(file);
	};

	render(){ 
		document.title = 'Tofa Profile'
		firebase.auth().onAuthStateChanged(function(user) {
			if (user) {
			  getUser(user.uid).on('value', snapshot => {
				const founduser = snapshot.val();
				founduser.uid = user.uid;
				founduser.emailVerified = user.emailVerified
				if (!this.state.user){
					this.setState({
						user: founduser,
						address: founduser.addressIndex || 0,
						addresses: founduser.addresses || [],
						birthday: founduser.birthday,
						profile_picture: 'loading..'
					})
					const profile_pic_path = 'profile_pictures/'+this.state.user.first_name+this.state.user.last_name+this.state.user.uid
					downloadFile(profile_pic_path).then((url) => {
				        this.setState({
				          profile_picture: url,
				        })
				      })
				      .catch((error) => {
				        console.log('Error downloading image: ', error);
				    });

				}
				$("#firstName").val(founduser.first_name);
				$("#lastName").val(founduser.last_name);
				$("#email").val(founduser.email);
				$("#birthdate").val(founduser.birthday);

			  });
			} else {
			  // No user is signed in.
			  Swal.fire({
		        icon: 'error',
		        title: 'Oops...',
		        text: "Error 403: You are not signed in",
		        footer: "Redirecting you to home page"
		      })
			  window.open('/', '_self');
			}
		}.bind(this));

		const profileFileInput = document.getElementById('input');
		if (profileFileInput){
			profileFileInput.onchange = () => {
			  const selectedFile = profileFileInput.files[0];
			  console.log(selectedFile);
			}
		}
		$("#profile-picture-file").css('display', 'none')

		let contentStyle = {
    		display: "flex",
    		justifyContent: "center",
    		marginTop: "80px"
    	}
    	let uploadPromptStyles = {
			width: "95%",
			marginTop: "60px",
			marginLeft: "24px",
			display: 'none'
		}
		if (isMobile()) {
			contentStyle['marginLeft'] = "20px"
			uploadPromptStyles['width'] = '87%'

			$(".AddressInput-wrapper-3").css('width', '320px') // dev
			$(".jss3").css('width', '320px') // prod
		}

		if (this.state.profile_picture === 'avatar.png'){
			$('#upload-prompt').css('display', 'block')
		}

		return (
			<div className="ProfilePage">
				<AppHeader />
				<div
					id="upload-prompt"
					className="alert alert-warning fade show alert-dismissible"
					style={uploadPromptStyles}
				>
					<p style={{cursor: 'pointer'}} className="close" data-dismiss="alert" aria-label="close" title="close">×</p>
					<strong>Hello there!</strong> <p>Upload a picture to be recognized by your friends</p>
				</div>
			    <div style={contentStyle}>
			    	<div>

			    		
						<label htmlFor="contained-button-file">
						  <IconButton>
						   <img
						   	 alt="Profile Pic" 
						     src={this.state.profile_picture}
						     id="profile-pic-avatar"
						     onClick={this.handleProfileClick}
						     style={{
						      width: "120px",
						      height: "120px",
						      borderRadius: "50%"
						     }} 
						    />
						  </IconButton>
						</label>
						<input
						 accept="image/*"
						 id="profile-picture-file"
						 multiple
						 type="file"
						 style={{diplay: "none"}}
						 onChange={this.handleProfilePicChange}
						/>


					    <br></br><br></br>
				    	<TextField id="firstName" label="" defaultValue="" onChange={this.handleNameChange}/>
				    	<br></br><br></br>
				    	<TextField id="lastName" label="" defaultValue="" onChange={this.handleNameChange}/>
						<br></br><br></br>
						<TextField disabled id="email" label="" defaultValue="" />
						<br></br><br></br>
						
				    	<TextField
							id="birthdate"
							label="Birthday"
							type="date"
							defaultValue=""
							className="birthDate"
							InputLabelProps={{
							  shrink: true,
							}}
							onChange={this.handleBirthday}
							helperText="So people know when to gift you 🎂"
						/>
						<br></br><br></br>

						<AddressInput
			                onAdd={this.handleAddAddress}
			                onChange={this.handleChangeAddress}
			                value={this.state.address}
			                allAddresses={this.state.addresses}
			                style={{width: "200px"}}
			                helperText="So people know where to gift you 🚚"
			            />
			            
			    	</div>
				    
			    </div>
		    </div>
		  )
	}
}

export default ProfilePage
