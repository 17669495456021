import React from 'react';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/styles';
import PersonCard from './person-card'


const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: 'white',
  },
  inline: {
    display: 'inline',
  },
});


class PeopleList extends React.Component {
  constructor (props) {
      super(props)
      this.state = {}
  }

  handleClick = (obj, nudge) => {
    this.props.handleClickOpen(obj, nudge);
  };

  render() {
    const { classes } = this.props;

    return (
      <List className={classes.root} style={{paddingBottom: "0px", paddingTop: '0px', width: '80%'}}>
        {Object.values(this.props.people).map((obj, index) => {
            return <PersonCard
                      firstName={obj.first_name}
                      lastName={obj.last_name}
                      uid={obj.objectID}
                      email={obj.email}
                      birthday={obj.birthday}
                      profile_picture={obj.profile_picture}
                      numItems={obj.num_items}
                      handleClickOpen={this.handleClick}
                  />
            })}
      </List>
    )
  }
}

  
export default withStyles(styles)(PeopleList)
