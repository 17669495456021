import React from 'react'
import ProductItem from './product-item'


class ProductItems extends React.Component{
	constructor (props) {
        super(props)
        this.state = {}
    }

	render(){
		return (
			<div className="WishList">
				<ul>
					{this.props.items.map((obj, index) => {
						return <div style={{margin: "20px"}}>
									<ProductItem
									item={obj}
									itemID={index}
									handleArchive={this.props.handleArchiveItem}
									handleMarkAsReceived={this.props.handleMarkAsReceivedItem}
									itemIDtoUpdate={this.props.itemIDtoUpdate}
									/>
								</div>
				    })}
				</ul>
	    	</div>
		  )
	}
}

export default ProductItems