import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';


const useStyles = makeStyles({
  list: {
    width: 250,
    height: "auto",
  },
  fullList: {
    width: 'auto',
  },
});

function timeDifference(date1,date2) {
    if (!date1 || !date2){
      return ''
    }

    const res = Math.abs(date1 - date2) / 1000;

    const days = Math.floor(res / 86400);

    if (days>0){
    	if (days===1) return days+" day ago"
    	return days+" days ago"
    } 

	// get hours        
	const hours = Math.floor(res / 3600) % 24;
	if (hours>0){
    	if (hours===1) return hours+" hour ago"
    	return Math.trunc(hours)+" hours ago"
    }      

	// get minutes
	const minutes = Math.floor(res / 60) % 60;
	if (minutes>0){
    	if (minutes===1) return minutes+" minute ago"
    	return Math.trunc(minutes)+" minutes ago"
    } 

	// get seconds
	const seconds = res % 60;
    if (seconds>0){
    	if (seconds===1) return seconds+" second ago"
    	return Math.trunc(seconds)+" seconds ago"
    } 
}
const notificationColor = (seen) => {
  	if (seen) return "grey"
  		return 'black'
  };

export default function NotificationDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    props.handleNotificationClose()
  };

  

  let notificationList = props.notifications || []
  if (notificationList.length===0){
      notificationList.push({
  		text: "No notifications",
  		timestamp: null,
      from_profile_picture: "logo.png",
      event: 'NO_NOTIFICATIONS'
  	})
  }
  	

  return (
    <div>
        <React.Fragment key={props.anchor}>
          <Drawer anchor={props.anchor} open={props.open} onClose={toggleDrawer(props.anchor, false)}>
            <div
		      className={clsx(classes.list, {
		        [classes.fullList]: props.anchor === 'top' || props.anchor === 'bottom',
		      })}
		      role="presentation"
		      onClick={toggleDrawer(props.anchor, false)}
		      onKeyDown={toggleDrawer(props.anchor, false)}
		    >
		      <List>
		        {
		        	notificationList.map((notification, index) => {
		        		let color = notificationColor(notification.seen)
		        		return (
			        	<div style={{color: color, marginBottom: "8px"}}>
				          <ListItem button key={notification.text}>
				          	<ListItemAvatar>
		                        <img
		                         alt="Someone" 
		                         src={notification.from_profile_picture || 'logo.png'}
		                         style={{
		                          width: "45px",
		                          height: "45px",
		                          borderRadius: "50%"
		                         }} 
		                        />
		                      </ListItemAvatar>
				            <ListItemText primary={notification.text} style={{wordBreak: "break-word"}} />

				          </ListItem>
				          <p style={{
				          	fontSize: "small",
				          	marginLeft: "72px"
				          }}>{timeDifference(Date.now(), notification.timestamp)}</p>
				          <Divider />
				        </div>
		        		)

		        	}

		        	)
		    	}
		      </List>
    </div>
          </Drawer>
        </React.Fragment>
    </div>
  );
}
