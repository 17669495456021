import {firebase} from './firebase'

export function writeUserData(userId, firstName, lastName, email, imageUrl, notifications) {
  console.log('writing user data')
  return firebase.database().ref('users/' + userId).set({
    first_name: firstName,
    last_name: lastName,
    email: email,
    profile_picture : imageUrl,
    notifications: notifications
  })
}

export function updateUser(userID, data){
	let updates = {}
	for (const [key, value] of Object.entries(data)) {
  		updates['/users/' + userID+'/'+key+'/'] = value;
  	}
  return firebase.database().ref().update(updates);
}

export function getUser(userID) {
	return firebase.database().ref('/users/'+userID)
}
