import React from 'react'
import {isMobile} from '../../utils'
import {firebase} from '../../firebase'
import {getUser} from '../../db-utils'


class HomePageHeader extends React.Component{
	constructor (props) {
        super(props)
        this.state = {
            user: null,
            homeLink: '/'
        }
    }

	render(){
		firebase.auth().onAuthStateChanged(function(user) {
			if (user) {
			  getUser(user.uid).on('value', snapshot => {
				const founduser = snapshot.val();
				founduser.uid = user.uid;
				if (this.state.user===null){
					this.setState({
						user: founduser,
						homeLink: '/wishlist'
					})
				}
			  });
			} else {
			}
		}.bind(this));

		let headerStyle = {padding: '0', paddingRight: "20px"}
		let iconStyle = {}
		if (isMobile()){
			headerStyle = {
				paddingTop: '0px',
				paddingBottom: '0px'
			}

			iconStyle['marginLeft'] = '-14px'

		}
		return (
		    <div className="HomePageHeader">
		    	<header>
			      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark" style={headerStyle}>
			        <a className="navbar-brand" href={this.state.homeLink} style={iconStyle}><img alt="Tofa Logo" src="/logo.png" style={{width: "65px", height: "65px", marginRight: "-17px", marginBottom: "6px"}}/></a>
			        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
			          <span className="navbar-toggler-icon"></span>
			        </button>
			        <div className="collapse navbar-collapse" id="navbarCollapse">
			          <ul className="navbar-nav mr-auto">
			            <li className="nav-item">
			              <a className="nav-link tofa-nav-link" href="/">Home <span className="sr-only">(current)</span></a>
			            </li>
			            <li className="nav-item">
			              <a className="nav-link tofa-nav-link" href="/about">About</a>
			            </li>
			            <li className="nav-item" tabIndex="0" data-toggle="tooltip" title="We need you for success stories">
			              <a className="nav-link disabled" href="/" tabIndex="-1" aria-disabled="true" style={{pointerEvents: "none"}}>Stories</a>
			            </li>
			          </ul>
			          <form className="form-inline mt-2 mt-md-0">
			          	<a href="/signin">
			            	<button className="btn btn-primary my-2 my-sm-0 login-button" type="button">Login</button>
			            </a>
			            <a href="/signup">
			            	<button className="btn btn-primary my-2 my-sm-1" type="button">Sign up</button>
			            </a>
			          </form>
			        </div>
			      </nav>
			    </header>
		    </div>
		  )
	}
}

export default HomePageHeader