export function validEmail(email) {
	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
		return true
	return false
}

export function validPassword(pwd) {
	if (pwd.length<8){
		console.log("Error: password must be atleast 8 characters long");
    	return false;
	}

	let re = /[0-9]/;
	if(!re.test(pwd)) {
    	console.log("Error: password must contain at least one number (0-9)!");
    	return false;
  	}
  	re = /[a-z]/;
  	if(!re.test(pwd)) {
  		console.log("Error: password must contain at least one lowercase letter (a-z)!");
	    return false;
	}
	return true
}

export function isMobile(){
	if (window.innerWidth<420)
		return true
	return false
}


