import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import $ from 'jquery';

const categories = [
  {
    value: 'clothing',
    label: 'Clothing',
  },
  {
    value: 'electronics',
    label: 'Electronics',
  },
  {
    value: 'sports-and-outdoors',
    label: 'Sports & Outdoors',
  },
  {
    value: 'health-and-wellness',
    label: 'Health & Wellness',
  },
  {
    value: 'appliances',
    label: 'Appliances',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export default function ProductFormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (cmd) => {
    setOpen(false);
    if (cmd==='save'){
      props.handleAddItem(
        $("#product-name").val(),
        $("#product-link").val(),
        $("#product-price").val(),
        $("#product-category").val(),
        $("#product-image").val()
        );
    }
  };

  const [currentCategory, setCategory] = React.useState(props.defaultCategory);
  // useEffect is needed to use the props.defaultCategory as a default value for currentCategory
  React.useEffect(() => {
       setCategory(props.defaultCategory);
   }, [props.defaultCategory])

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <div>
      <Button
        id="product-form-dialog"
        onClick={handleClickOpen}
        style={{display: "none"}}
      >
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add item</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>

          <TextField
            style={{display: "none"}}
            autoFocus
            margin="dense"
            id="product-image"
            label="Name"
            placeholder=""
            fullWidth
          />

          <TextField
            autoFocus
            margin="dense"
            id="product-name"
            label="Name"
            placeholder="eg - tennis shoes"
            fullWidth
          />
          <TextField
            margin="dense"
            id="product-link"
            label="Link"
            placeholder="eg - https://www.amazon.com/gp/product/B01AWLHRSO"
            type="url"
            fullWidth
          />
          <TextField
            margin="dense"
            id="product-price"
            label="Price (in $)"
            placeholder="eg - 25"
            type="number"
            fullWidth
          />
          <TextField
            id="product-category"
            select
            label="Category"
            value={currentCategory}
            onChange={handleCategoryChange}
            helperText="Please choose a category"
          >
            {categories.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>


        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('cancel')} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose('save')} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
