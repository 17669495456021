import axios from 'axios';

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const TOFA_EXTRACT_API = 'https://us-central1-tofa-309321.cloudfunctions.net/extract--amazon-product-details'

async function extractProduct(productURL) {
    if (process.env.NODE_ENV==="development"){
        await sleep(1000);
        return {
            data: {
                success: true,
                data: {
                    title: "Wilson Federer Tennis Racquet",
                    description: "Wilson, the iconic American tennis brand, was built by people with a deep devotion to the game. It was built on a promise: to elevate the millions of athletes around the world, at every level of the sport, who call Wilson their own. We've been keeping that promise for decades and our legacy is legend. Today, we're still building, relentlessly pushing the limits of performance and design to equip a new generation of players with products worthily of our name. Respect the past. Own the future. Wilson is tennis.",
                    image: "http://ecx.images-amazon.com/images/I/41ZlQn5gRlL._SL160_.jpg",
                    images: ["http://ecx.images-amazon.com/images/I/41ZlQn5gRlL._SL160_.jpg"],
                    price: 34.99
                }
            }
        }
    } else {
        const params = {url: productURL}
        const api = TOFA_EXTRACT_API
        return axios.post(api, params)
    }
    
}

export { extractProduct };
