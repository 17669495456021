import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {getUser} from '../../db-utils'
import {TOFA_BACKGROUND_LIGHT_BLUE} from '../../colors'
import Card from '@material-ui/core/Card';
import {isMobile} from '../../utils'

class PersonCard extends React.Component {
  constructor (props) {
      super(props)
      this.state = {
          uid: props.uid,
          user: {
            uid: null
          },
          num_items: props.numItems,
          notifications: [],
          items: []
      }
  }

  requestDetails = (userID) => {
    // GET USER INFO
    getUser(userID).on('value', snapshot => {
      const founduser = snapshot.val();
      founduser.uid = userID
      this.setState({
        user: founduser,
        notifications: founduser.notifications || [],
      })
    });
  };

  handleClick = (obj, wishlistitems) => {
    let nudge = false

    let items = []
    if (this.state.user && this.state.user.items){
      items = this.state.user.items
    }
    if (items.length===0){
      nudge = true
    }
    this.props.handleClickOpen(this.state.user, nudge);
  };

  render() {
    let PersonNameStyle = {marginTop: '45px', marginLeft: '25px'}
    let PersonSubtextStyle = {marginTop: '0px', marginLeft: '25px'}
    if (isMobile()){
      PersonNameStyle['marginLeft'] = '20px'
      PersonNameStyle['marginTop'] = '28px'
      PersonSubtextStyle['marginLeft'] = '20px'
    }

    const wishlistitems = this.state.user.items || []
    let subtext = ''
    let num_items = this.state.num_items || 0
    if (num_items === 0){
      subtext = 'has not wished anything 😞 \n nudge them to add '
    }
    else if (num_items === 1){
      subtext = 'has wished '+num_items+' item 🎁'
    } else {
      subtext = 'has wished '+num_items+' items 🎁'
    }
    if (this.props.uid !== this.state.user.uid){
      this.requestDetails(this.props.uid)
    }

    return (
            <div style={{cursor: 'pointer'}}>
              <Card>
                <ListItem elevation="1" alignItems="flex-start" onClick={() => {this.handleClick(this.props.uid, wishlistitems)}}>
                  <ListItemAvatar>
                    <img
                     alt={this.props.firstName+' '+this.props.lastName}
                     src={this.props.profile_picture || 'avatar.png'}
                     id={this.state.uid+'profile-pic'}
                     style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%"
                     }} 
                    />
                  </ListItemAvatar>
                  <div>
                    <ListItemText
                      primary={this.props.firstName+' '+this.props.lastName}
                      style={PersonNameStyle}
                    />
                    <ListItemText
                      primary={subtext}
                      style={PersonSubtextStyle}
                    />
                    </div>
                </ListItem>
                <Divider variant="inset" component="li" style={{marginBottom: '10px'}}/>
              </Card>
              <div style={{height: '20px', backgroundColor: TOFA_BACKGROUND_LIGHT_BLUE}}></div>

            </div>
      )
    }
}

export default PersonCard
