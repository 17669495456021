import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {firebase} from '../firebase'
import Swal from 'sweetalert2'
import $ from 'jquery';
import {getUser} from '../db-utils'

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ForgotPasswordPage extends React.Component {
  constructor (props) {
        super(props)
        this.state = {
            user: null,
        }
    }

  handleSendEmail = () => {
    const email = $("#email").val();
    console.log('forgot password ', email);

    const auth = firebase.auth();
    auth.sendPasswordResetEmail(email).then(function() {
      // Email sent.
      Swal.fire({
        icon: 'success',
        title: 'Email sent',
        text: "Please check your inbox"
      })

    }).catch(function(error) {
      // An error happened.
      console.log('error: ', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
        footer: error.code
      })
    });

  };

  render(){
    const { classes } = this.props;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        getUser(user.uid).on('value', snapshot => {
        const founduser = snapshot.val();
        founduser.uid = user.uid;
        if (!this.state.user){
          this.setState({
            user: founduser,
            items: founduser.items || []
          })
        }
        window.open('/wishlist', '_self');
        });
      } else {
        
      }
    }.bind(this));


    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={this.handleSendEmail}
              style={{
                backgroundColor: "#007bff",
                color: "white"
              }}
            >
              Send Reset Password
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/signin" variant="body2" style={{
                  color: "#007bff"
                }}>
                  Back to sign in?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2" style={{
                  color: "#007bff"
                }}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(ForgotPasswordPage);
