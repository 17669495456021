import React from 'react'
import AppHeader from './app-header'
import CreateItemDialog from './molecules/create-item-dialog'
import ProductLinkDialog from './molecules/product-link-dialog'
import ProductFormDialog from './molecules/product-form-dialog'
import $ from 'jquery';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductItems from './molecules/product-items'
import {firebase, index} from '../firebase'
import Swal from 'sweetalert2'
import {getUser, updateUser} from '../db-utils'
import { extractProduct } from '../extract-utils'
import {TOFA_BACKGROUND_LIGHT_BLUE} from '../colors'
import {sleep} from '../extract-utils'


class WishListPage extends React.Component{
    constructor (props) {
        super(props)
        this.state = {
            defaultCategory: '',
            user: null,
            items: [],
            itemIDtoUpdate: null
        }
    }

    displayCircularProgress = () => {
        $("#circular-progress").css("display", "block");
    };
    hideCircularProgress = () => {
        $("#circular-progress").css("display", "none");
    };

    handleCreateItemDialogClose = (cmd) => {
        if (cmd === 'paste-link'){
            $("#product-link-dialog").click();
        }
        else if (cmd === 'type-it-in'){
            this.setState({
                defaultCategory: ''
            })
            $("#product-form-dialog").click();
        }
    };

    handleProductLinkClose = (cmd) => {
        if (cmd === 'extract') {
            this.displayCircularProgress();

            const link = $("#extract-product-link").val();
            extractProduct(link).then((res)=>{
                console.log(res.data);
                this.hideCircularProgress();
                if (res.data.success){
                    $("#product-form-dialog").click();
                    $("#product-name").val(res.data.data.title);
                    $("#product-link").val(link || "https://www.amazon.com/gp/product/B01AWLHRSO?th=1&psc=1");
                    $("#product-price").val(res.data.data.price);
                    $("#product-image").val(res.data.data.image);
                    this.setState({
                        defaultCategory: 'clothing'
                    });
                } else {
                    console.log(res);
                    Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Problem extracting data",
                    footer: "Please type it in."
                  })
                }
                
            }).catch((error)=>{
                this.hideCircularProgress();
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Problem extracting data",
                    footer: "Please type it in."
                  })
            });
                        
        }
    };

    handleAddItem = (name, link, price, category, image_link) => {

        const newItem = {
            name: name,
            done: false,
            link: link,
            price: price,
            category: category,
            image_link: image_link
        };
        const newItems = [newItem, ...this.state.items];
        const updateState = () => {
            this.setState({
                items: newItems
            })
        };

        // update algolia index
        const record = {
            objectID: this.state.user.uid,
            first_name: this.state.user.first_name,
            last_name: this.state.user.last_name,
            email: this.state.user.email,
            num_items: newItems.length
        }
        index.saveObject(record)

        // update DB
        updateUser(this.state.user.uid, {items: newItems}).then(function(){
            updateState();

            Swal.fire(
              'Created!',
              'Item has been created successfully.',
              'success'
            )
        }).catch(function(err){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
                footer: "Something went wrong adding your item"
              });
        });
    };

    handleArchiveItem = (itemID) => {
        Swal.fire({
          title: 'Are you sure you want to delete this item?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            let newItems = []
            for (let index in this.state.items){
                if (Number(index) !== Number(itemID)) {
                    newItems.push(this.state.items[index])
                }
            }

            const record = {
                objectID: this.state.user.uid,
                first_name: this.state.user.first_name,
                last_name: this.state.user.last_name,
                email: this.state.user.email,
                num_items: newItems.length
            }
            index.saveObject(record)

            // update DB
            const updateState = () => {
                this.setState({
                    items: newItems
                })
            };
            updateUser(this.state.user.uid, {items: newItems}).then(function(){
                updateState();
            }).catch(function(err){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err,
                    footer: "Something went wrong adding your item"
                  });
            });

            Swal.fire(
              'Deleted!',
              'Item has been deleted.',
              'success'
            )
          }
        });
    }

    handleMarkAsReceivedItem = (itemID) => {
        Swal.fire({
          title: 'Are you sure you want to cross this off your wishlist?',
          text: "You won't be able to revert this",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, cross it off!'
        }).then((result) => {
          if (result.isConfirmed) {
            let updated = [...this.state.items];
            for (let id in updated) {
                if (Number(id)===Number(itemID)){
                    updated[id].done = true;
                }
            }


            // update DB
            const updateState = () => {
                this.setState({
                    items: updated
                });
            };
            updateUser(this.state.user.uid, {items: updated}).then(function(){
                updateState();
            }).catch(function(err){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err,
                    footer: "Something went wrong adding your item"
                  });
            });

            Swal.fire(
              'Done!',
              'Crossed off your list 😎',
              'success'
            )
          }
        });
    }

    updateItems = () => {
        const itemID = $("#invisible-update-items-button").attr("itemID")
        const updatedItems = []
        for (const i in this.state.items){
            updatedItems.push(this.state.items[i])
            if (String(i)===String(itemID)){
                updatedItems[i].done = true
            }
        }

        this.setState({
            items: updatedItems,
            itemIDtoUpdate: itemID
        })

        // HIGHLIGHT item card to show that its crossed off
        const itemDetailsCardID = "#item-details-card-"+itemID
        // set starting color as grey
        $(itemDetailsCardID).css("background-color", "lightgrey")

        // scroll to card
        $('html').animate({
            scrollTop: ($(itemDetailsCardID).offset().top)
        },500);

        // after 0.5s, transistion color to white
        sleep(500).then(()=>{
            $("#item-details-card-"+itemID).css("background-color", "white")
        })  
    }

    render(){
        console.log(process.env.NODE_ENV);

        document.title = 'Tofa Wishlist'
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
              getUser(user.uid).on('value', snapshot => {
                const founduser = snapshot.val();
                founduser.uid = user.uid;
                founduser.emailVerified = user.emailVerified
                if (!this.state.user){
                    this.setState({
                        user: founduser,
                        items: founduser.items || []
                    })
                }

                this.hideCircularProgress();

              });
            } else {
              // No user is signed in.
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Error 403: You are not signed in",
                footer: "Redirecting you to home page"
              })
              window.open('/', '_self');
            }
        }.bind(this));

        return (
            <div className="WishListPage" style={{backgroundColor: TOFA_BACKGROUND_LIGHT_BLUE}}>
                <AppHeader page="wishlistpage" />
                <button
                    id="invisible-update-items-button"
                    style={{display: 'none'}}
                    onClick={this.updateItems}
                    >
                </button>

                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <CircularProgress id="circular-progress" style={{
                        position: "absolute",
                        marginTop: "18%",
                        zIndex: "10",
                        display: "block"
                    }}/>
                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "80px"
                }}>
                    <CreateItemDialog
                        handleCreateItemDialogClose={this.handleCreateItemDialogClose}
                    />
                    <ProductLinkDialog
                        handleProductLinkClose={this.handleProductLinkClose}
                    />
                    <ProductFormDialog 
                        defaultCategory={this.state.defaultCategory}
                        handleAddItem={this.handleAddItem}
                    />

                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px"
                }}>
                    <ProductItems
                    items={this.state.items}
                    itemIDtoUpdate={this.state.itemIDtoUpdate}
                    handleArchiveItem={this.handleArchiveItem}
                    handleMarkAsReceivedItem={this.handleMarkAsReceivedItem}
                    />
                </div>

            </div>
          )
    }
}

export default WishListPage
