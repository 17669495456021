import {firebase} from './firebase'

export function uploadFile(path, data_url) {
  var storageRef = firebase.storage().ref();
  storageRef.child(path).putString(data_url, 'data_url').then((snapshot) => {}).catch((err)=> {
    console.log("error uploading file to blob: ", err)
  });
}

export function downloadFile(path){
  var storage = firebase.storage();
  return storage.ref(path).getDownloadURL()
}
