import React from 'react'
import AppHeader from './app-header'
import {firebase, index} from '../firebase'
import Swal from 'sweetalert2'
import {getUser} from '../db-utils'
import SearchBar from "material-ui-search-bar"
import PeopleList from './molecules/people-list'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery'
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductResults from './molecules/product-results'
import {sleep} from '../extract-utils'
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import {isMobile} from '../utils'
import {sendEmail} from '../email-utils'
import {downloadFile} from '../blob-utils'

class FriendsPage extends React.Component{
    constructor (props) {
        super(props)
        this.state = {
            text: '',
            user: null,
            dataSource: [],
            results: {},
            dialogOpen: false,
            dialogTitle: '',
            dialogImg: '',
            dialogItems: [],
            dialogPerson: null
        }
    }

    displayCircularProgress = () => {
        $("#circular-progress").css("display", "block");
    };
    hideCircularProgress = () => {
        $("#circular-progress").css("display", "none");
    };

    handleClickOpen = (obj, nudge) => {
        if (nudge) {
            Swal.fire({
              title: 'Do you want to nudge '+obj.first_name+' '+obj.last_name+'?',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: `Nudge`,
              icon: 'question'
            }).then((result) => {
              if (result.isConfirmed) {
                // send email
                let link = window.origin+'/wishlist'
                const html = `
                      <div>
                        <p>Hi `+obj.first_name+`, </p>
                        <p>Someone nudged you on to add some items in your Tofa wishlist 🤫. See who</p>
                        <a href="`+link+`">`+link+`</a>
                      </div>
                    `
                sendEmail({
                    to: obj.email,
                    subject: "Someone nudged you on Tofa",
                    html: html
                }).then((res)=>{
                    console.log('Email sent: ', res)

                })

                const newNotification = {
                    text: this.state.user.first_name+" "+this.state.user.last_name+" has nudged you to add some items in you wishlist",
                    from_profile_picture: this.state.user.profile_picture,
                    timestamp: Date.now(),
                    seen: false,
                    event: 'NUDGE'

                }

                // push notification to real time db
                this.displayCircularProgress()
                var notificationListRef = firebase.database().ref('users/'+obj.uid+'/notifications');
                var newNotificationRef = notificationListRef.push();
                newNotificationRef.set(newNotification).then(()=>{
                    this.hideCircularProgress()
                    Swal.fire('Nudged!', '', 'success')
                }).catch(function(err){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops something went wrong',
                        text: err,
                      });
                });
              } else if (result.isDismissed) {
              }
            })

        } else if (obj) {
            this.setState({
                dialogOpen: true,
                dialogTitle: obj.first_name + ' ' + obj.last_name,
                dialogImg: obj.profile_picture || 'avatar.png',
                dialogItems: obj.items || [],
                dialogResults: [],
                dialogPerson: obj
            })

        }
    };

    handleClose = () => {
        this.setState({dialogOpen: false})
    };

    findItem = () => {
        this.displayCircularProgress();
        this.setState({dialogResults: []})
        sleep(1000).then(()=>{
            this.hideCircularProgress()
            if ($('#desired-price').val()) {
                const desiredAmount = $('#desired-price').val()
                let results = []
                for (const item of this.state.dialogItems){
                    if (parseInt(desiredAmount)>=parseInt(item.price) || parseInt(desiredAmount)>=(parseInt(item.price)-20))
                        results.push(item)
                }
                this.setState({dialogResults: results})
                $('#product-list').css('display', 'flex')
            }
            if (this.state.dialogResults.length===0){
                $('#result-text').css('display', 'block')
            } else {
                $('#result-text').css('display', 'none')
            }
            
            
        })
        
    }

    onTextChange = (text) => {
        if (text){

            this.setState({text: text})
            index.search(text).then(({ hits }) => {
              let results = {}
              for(const i in hits) {
                const profile_pic_path = 'profile_pictures/'+hits[i].first_name+hits[i].last_name+hits[i].objectID
                downloadFile(profile_pic_path).then((url) => {
                    hits[i]["profile_picture"] = url
                    results[hits[i].objectID] = hits[i]
                    this.setState({results: results})
                  })
                  .catch((error) => {
                    console.log('Error downloading image: ', error);
                    results[hits[i].objectID] = hits[i]
                    this.setState({results: results})
                });
              }
            });
        } else {
            this.setState({results: {}})
        }
        
    };

    searchItem = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13){
            this.findItem()
        }
    };

    render(){
        document.title = 'Tofa Friends'

        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
              getUser(user.uid).on('value', snapshot => {
                const founduser = snapshot.val();
                founduser.uid = user.uid;
                if (!this.state.user){
                    this.setState({
                        user: founduser,
                        items: founduser.items || []
                    })
                }
              });
            } else {
              // No user is signed in.
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Error 403: You are not signed in",
                footer: "Redirecting you to home page"
              })
              window.open('/', '_self');
            }
        }.bind(this));

        let dialogTitleStyles = {float: 'right', marginLeft: "22%", marginTop: "-18%"}
        let budgetInputStyles = {marginTop: '40px', marginLeft: '20px', width: '94%'}
        let dialogProfilePicStyles = {
          width: "120px",
          height: "120px",
          borderRadius: "50%",
          margin: "3%"
        }
        let NoItemsStyles = {margin: '3%', color: '#ec7f7f'}
        let NoItemsVariant = 'h5'
        if (isMobile()) {
            dialogTitleStyles['marginLeft'] = '85px'
            dialogTitleStyles['marginTop'] = '-23%'

            budgetInputStyles['marginLeft'] = '15px'
            budgetInputStyles['width'] = '90%'

            dialogProfilePicStyles = {
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              marginLeft: "15px",
              marginTop: '5%'
             }

             NoItemsStyles['marginLeft'] = '-50px'
             NoItemsStyles['marginTop'] = '-15px'
             NoItemsVariant = 'body'
        }

        return (
            <div className="FriendsPage">
                <AppHeader />

                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <SearchBar
                        placeholder="Search your friends"
                        onChange={(value) => this.onTextChange(value)}
                        onRequestSearch={() => console.log('Request search')}
                        cancelOnEscape={true}
                        onCancelSearch={(value) => this.onTextChange(value)}
                        style={{
                            margin: '0 auto',
                            maxWidth: 800,
                            width: "80%",
                            marginTop: "80px"
                        }}/>


                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px"
                }}>
                    <PeopleList people={this.state.results} user={this.state.user} handleClickOpen={this.handleClickOpen}/>
                </div>

                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                  >
                    <img
                     alt="Profile Pic" 
                     src={this.state.dialogImg}
                     id="profile-pic-avatar"
                     style={dialogProfilePicStyles} 
                    />
                    <DialogTitle id="alert-dialog-title" style={dialogTitleStyles}>{this.state.dialogTitle}</DialogTitle>
                    <TextField
                            margin="dense"
                            id="desired-price"
                            label="Enter your budget ($): "
                            placeholder="eg - 25"
                            type="number"
                            style={budgetInputStyles}
                            autoFocus
                            onKeyDown={this.searchItem}
                          />

                    <DialogContent style={{marginTop: "4%", paddingBottom: "5%"}}>
                      <DialogContentText id="alert-dialog-description">
                        
                          
                      </DialogContentText>
                      <div id="product-list" style={{
                            display: "none",
                            justifyContent: "center",
                            marginRight: "6%",
                        }}>
                            <Typography id='result-text' variant={NoItemsVariant} style={NoItemsStyles}>
                              No items in that budget
                            </Typography>
                            <List>
                                <ProductResults
                                    items={this.state.dialogResults}
                                    targetPerson={this.state.dialogPerson}
                                    user={this.state.user}
                                    displayCircularProgress={this.displayCircularProgress}
                                    hideCircularProgress={this.hideCircularProgress}
                                />
                            </List>
                        </div>


                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.findItem} color="primary">
                        Find item
                      </Button>
                    </DialogActions>
                    
                    <CircularProgress id="circular-progress" style={{
                        position: "absolute",
                        marginLeft: '50%',
                        marginTop: '20%',
                        display:'none'
                    }}/>
                    
                   
                 </Dialog>

                
            </div>
          )
    }
}

export default FriendsPage
