import React from 'react'
import HomePageHeader from './home-page-header'
import "../../index.css"

class HomePage extends React.Component{
	render(){
		return (
		    <div className="HomePage">
		    	<HomePageHeader />
		    	<main role="main">
			      <div id="myCarousel" className="carousel slide" data-ride="carousel">
			        <ol className="carousel-indicators">
			          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
			          <li data-target="#myCarousel" data-slide-to="1"></li>
			          <li data-target="#myCarousel" data-slide-to="2"></li>
			        </ol>
			        <div className="carousel-inner">
			          <div className="carousel-item active">

			            <img src="candy-canes-gifts.jpg" alt="candy-canes" className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
			            <div className="container">
			              <div className="carousel-caption text-left">
			                <h1>Tofa!</h1>
			                <p>Gift your loved ones what they want.</p>
			                <p><a className="btn btn-lg btn-primary" href="/about" role="button">Learn more</a></p>
			              </div>
			            </div>
			          </div>
			          <div className="carousel-item">
			            <img src="giving-gift.jpg" alt="giving gifts" className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" ></img>
			            <div className="container">
			              <div className="carousel-caption">
			                <h1>Register Today</h1>
			                <p>Create your Tofa wishlist for free! Just add products you wish to receive as gifts.</p>
			                <p><a className="btn btn-lg btn-primary" href="/signup" role="button">Sign up for free!</a></p>
			              </div>
			            </div>
			          </div>
			          <div className="carousel-item">
			            <img src="golden-gift.jpg" alt="a golden gift" className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" ></img>
			            <div className="container">
			              <div className="carousel-caption text-right">
			                <h1>Find you friends!</h1>
			                <p>Find your friends in Tofa and send them meaningful presents.</p>
			                <p><a className="btn btn-lg btn-primary" href="/" role="button">Example</a></p>
			              </div>
			            </div>
			          </div>
			        </div>
			        <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
			          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
			          <span className="sr-only">Previous</span>
			        </a>
			        <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
			          <span className="carousel-control-next-icon" aria-hidden="true"></span>
			          <span className="sr-only">Next</span>
			        </a>
			      </div>


			      {/* Marketing messaging and featurettes
			      ================================================== */}
			      {/* Wrap the rest of the page in another container to center all the content. */}

			      <div className="container marketing">

			        {/* 2 columns of text below the carousel */}
			        <div className="row">
			          <div className="col-lg-6">
			            <img src="wishlist.png" alt="create your wishlist" className="bd-placeholder-img rounded-circle" width="160" height="160" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false"  aria-label="Placeholder: 140x140"></img>
			            <br></br>
			            <h2>Create your Profile</h2>
			            <p>Sign up and start creating your Tofa wishlist. You can provide links to products, assign priorities, and control the visibility of them. </p>
			          </div>{/* /.col-lg-4 */}

			          <div className="col-lg-6">
			            <img src="friends.png" alt="find your friends" className="bd-placeholder-img rounded-circle" width="160" height="160" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false"  aria-label="Placeholder: 140x140"></img>
			            <br></br>
			            <h2>Find your friends</h2>
			            <p>You can find your friends in Tofa and see what presents they would like to receive. You can just filter by the amount you would like to spend and see what fits their needs! They will love it!</p>
			          </div>{/* /.col-lg-4 */}
			        </div>{/* /.row */}


			        {/* START THE FEATURETTES */}

			        <hr className="featurette-divider"></hr>

			        <div className="row featurette">
			          <div className="col-md-7">
			            <h2 className="featurette-heading">No more <span className="text-muted">wondering.</span></h2>
			            <p className="lead">"What should I get John for his birthday? Does he even like NBA 2k? What if he already has that game?"</p>
			          </div>
			          <div className="col-md-5">
			            <img src="thinking.jpg" alt="thinking hard" style={{height: "100%"}} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false"  aria-label="Placeholder: 500x500"></img>
			          </div>
			        </div>

			        <hr className="featurette-divider"></hr>

			        <div className="row featurette">
			          <div className="col-md-7 order-md-2">
			            <h2 className="featurette-heading">Our <span className="text-muted">mission?</span></h2>
			            <p className="lead">We aim to ensure that any gift for any occasion fulfills the recipient's needs & the giver's budget. Find your friends in Tofa and filter their preferences by your preferred amount to spend.</p>
			          </div>
			          <div className="col-md-5 order-md-1">
			            <img src="mission.jpg" alt="on a mission" style={{height: "100%"}} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false"  aria-label="Placeholder: 500x500"></img>
			          </div>
			        </div>

			        <hr className="featurette-divider"></hr>

			        <div className="row featurette">
			          <div className="col-md-7">
			            <h2 className="featurette-heading">What is <span className="text-muted">Tofa?</span></h2>
			            <p className="lead">Tofa means 'Gift' in Hindi. Tofa is a marketplace platform that connects friends and encourages them to give gifts according to the recipient's preferences. Tofa integrates with Amazon's API to display accurate product information.</p>
			          </div>
			          <div className="col-md-5">
			            <img src="gift.jpg" alt="a gift" style={{height: "100%"}} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false"  aria-label="Placeholder: 500x500"></img>
			          </div>
			        </div>

			        <hr className="featurette-divider"></hr>

			        {/* /END THE FEATURETTES */}

			      </div>{/* /.container */}


			      {/* FOOTER */}
			      <footer className="container">
			        <p className="float-right"><a href="/signup">Sign Up</a> &middot; <a href="/">Back to top</a></p>
			        <p>&copy; 2020-Present Tofa, Inc. &middot; <a href="/">Privacy</a> &middot; <a href="/">Terms</a></p>
			      </footer>
			    </main>
		    </div>
		  )
	}
}

export default HomePage