import React from 'react'
import {firebase} from '../firebase'
import Swal from 'sweetalert2'
import IconButton from '@material-ui/core/IconButton';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationDrawer from './molecules/notification-drawer'
import {getUser, updateUser} from '../db-utils'
import {isMobile} from '../utils'
import Badge from '@material-ui/core/Badge';
import $ from 'jquery'

class AppHeader extends React.Component{
	constructor (props) {
        super(props)
        this.state = {
            drawerOpen: false,
            notifications: null,
            user: null,
            homeLink: '/'
        }
    }

	handleSignOut = () => {
		firebase.auth().signOut().then(() => {
		  // Sign-out successful.
		  window.open('/', '_self');
		}).catch((error) => {
		  Swal.fire({
		        icon: 'error',
		        title: 'Oops...',
		        text: error,
		        footer: "Something went wrong while signing out"
		      });
		});
	};

	handleNotificationClick = () => {
		this.setState({drawerOpen: true})
	};

	handleNotificationClose = () => {
		const newNotifications = []
		const notifications = this.state.notifications || []
		for (const notification of notifications){
			if (notification.id){
				const key = 'notifications/'+notification.id+'/seen'
				const updateData = {
					[key]: true
				}

				updateUser(this.state.user.uid, updateData).then(()=>{
			  		
			  	}).catch(function(err){
		        	console.log('Something went wrong: marking all notifications as seen');
				});

				let newNotification = Object.assign({}, notification)
				newNotification.seen = true
				newNotifications.push(newNotification)
			}
		}
		this.setState({
			notifications: newNotifications,
			drawerOpen: false
		})
		
	};

	handleSendVerificationEmail = () => {
		let user = firebase.auth().currentUser;
		user.sendEmailVerification().then(function() {
		  Swal.fire({
		        icon: 'success',
		        title: 'Email sent'
		      });
		  $("#closeEmailPrompt").click();

		}).catch(function(error) {
		  Swal.fire({
		        icon: 'error',
		        title: 'Oops...',
		        text: error,
		        footer: "Something went wrong sending email"
		      });
		});

	};

	render(){
		firebase.auth().onAuthStateChanged(function(user) {
			if (user) {
			  getUser(user.uid).on('value', snapshot => {
				const founduser = snapshot.val();
				founduser.uid = user.uid;
				founduser.emailVerified = user.emailVerified

				if (this.state.user===null){
					this.setState({
						user: founduser,
						homeLink: '/wishlist'
					})
				}

				// query for notifications
				if (this.state.notifications===null){

					firebase.database()
						.ref('users/'+user.uid+'/notifications')
						.orderByChild('timestamp')
						.limitToLast(50)
						.on("value", function(data) {
							let notification_list = []
							const notificationObj = data.val()

							for (const notificationID in notificationObj){
								let notifObject = Object.assign({}, notificationObj[notificationID])
								notifObject.id = notificationID
								notification_list.push(notifObject)

								// if there is a new notification of event GIFT
								if (notificationObj[notificationID].seen === false
									&& notificationObj[notificationID].event === "GIFT"
									&& this.props.page==="wishlistpage"){
									// celebrate with confetti and swal
									Swal.fire({
									  title: notificationObj[notificationID].text,
									  showDenyButton: true,
									  showCancelButton: false,
									  confirmButtonText: `Cross it off my list`,
									  denyButtonText: `I'll do it myself`,
									}).then((result) => {
									  if (result.isConfirmed) {
									    // update user items and notifications
									    if (notificationObj[notificationID].itemID===0
									    	|| notificationObj[notificationID].itemID) {
											const items_key = 'items/'+notificationObj[notificationID].itemID+'/done'
											const notifications_key = 'notifications/'+notificationID+'/seen'
											const updateData = {
												[items_key]: true,
												[notifications_key]: true
											}
											updateUser(this.state.user.uid, updateData).then(()=>{
												$("#invisible-update-items-button").attr("itemID", notificationObj[notificationID].itemID)
												$("#invisible-update-items-button").click()
										  	}).catch(function(err){
									        	console.log('Something went wrong: marking notification as seen');
											});
										}
									  } else if (result.isDismissed) {
									  }
									})

									window.party.confetti(document.querySelector("#root"), {
										count: window.party.variation.range(150, 250)
									})
								}
							}
							// TODO: avoid reverse, maybe store timestamps as negative?
							notification_list.reverse()
							this.setState({
								notifications: notification_list,
							})
						}.bind(this));
				}
			  });
			} else {
			}
		}.bind(this));

		if (isMobile()){
			// if I want to change the mobile style of the notification icon
			//$("#NotificationsIcon").css('display', 'none')
			//$("#NotificationsText").css('display', 'block')
		}

		let numNotifications = 0
		let numNotificationsHamburger = 0
		if (this.state.notifications){
			for (const notification of this.state.notifications){
				if (notification.seen === false){
					numNotifications += 1
				}
			}
		}

		let headerStyle = {padding: '0', paddingRight: "20px"}
		let iconStyle = {}
		let verifyEmailPromptStyles = {
			width: "95%",
			marginTop: "40px",
			marginLeft: "24px",
			marginBottom: "-45px",
			height: "72px",
			display: 'none'
		}

		if (isMobile()){
			numNotificationsHamburger = numNotifications

			headerStyle = {
				paddingTop: '0px',
				paddingBottom: '0px'
			}
			iconStyle['marginLeft'] = '-14px'
			verifyEmailPromptStyles['width'] = '87%'
		}

		if (this.state.user && !this.state.user.emailVerified){
			verifyEmailPromptStyles['display'] = 'block'
		}
		

		return (
		    <div className="AppHeader">
		    	<header>
			      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark" style={headerStyle}>
			        <a className="navbar-brand" href={this.state.homeLink} style={iconStyle}><img alt="Tofa Logo" src="/logo.png" style={{width: "65px", height: "65px", marginRight: "-17px", marginBottom: "6px"}}/></a>
			        <Badge color="secondary" badgeContent={numNotificationsHamburger}>
				        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
				          <span className="navbar-toggler-icon"></span>
				        </button>
			        </Badge>
			        <div className="collapse navbar-collapse" id="navbarCollapse">
			          <ul className="navbar-nav mr-auto">
			            <li className="nav-item">
			              <a className="nav-link tofa-nav-link" href="/friends">Find Friends</a>
			            </li>
			            <li className="nav-item">
			              <a className="nav-link tofa-nav-link" href="/wishlist">Wishlist</a>
			            </li>
			            <li className="nav-item">
			              <a className="nav-link tofa-nav-link" href="/profile">Profile</a>
			            </li>
			          	
			          </ul>

			          <ul className="navbar-nav ml-auto">
			          	<li className="nav-item" id="NotificationsIcon">
			          		<IconButton
			          			onClick={this.handleNotificationClick}
			          			style={{marginRight: "6px"}}
			          			>

			          			<Badge color="secondary" badgeContent={numNotifications}>
				          		<NotificationsActiveIcon
				          			style={{
				          				marginTop: "-4px",
				          				color: "aliceblue"
				          			}} />
				          		</Badge>
			          		</IconButton>
			          	</li>
			          	<li
			          		className="nav-item"
			          		id="NotificationsText"
			          		style={{display: "none"}}
			          		onClick={this.handleNotificationClick}
			          		>
			          		<p className="nav-link tofa-nav-link" style={{marginBottom: "0px"}}> Notifications</p>
			          	</li>
			          	<li className="nav-item">
			              	<button className="btn btn-primary my-2 my-sm-0 login-button" type="button" onClick={this.handleSignOut}>Logout</button>
			            </li>
			          </ul>
			        </div>
			      </nav>
			    </header>
			    <div
					className="alert alert-warning fade show alert-dismissible"
					style={verifyEmailPromptStyles}
				>
					<p id="closeEmailPrompt" style={{cursor: 'pointer'}} className="close" data-dismiss="alert" aria-label="close" title="close">×</p>
					<strong>Please verify your email</strong>
					<p><a
						href={()=>{}}
						style={{
							color: '#007bff',
							cursor: 'pointer'
						}}
						onClick={this.handleSendVerificationEmail}>re-send email
						</a>
					</p>
				</div>

			    <NotificationDrawer
			    open={this.state.drawerOpen}
			    handleNotificationClose={this.handleNotificationClose}
			    anchor="right"
			    notifications={this.state.notifications}
			    />
		    </div>
		  )
	}
}

export default AppHeader
