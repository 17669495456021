import React from 'react'
import ProductResult from './product-result'


class ProductResults extends React.Component{
	constructor (props) {
        super(props)
        this.state = {}
    }

	render(){
		return (
			<div className="ProductResults">
				<ul>
					{this.props.items.map((obj, index) => {
						return <div style={{margin: "0px"}}>
									<ProductResult
									item={obj}
									itemID={index}
									targetPerson={this.props.targetPerson}
									user={this.props.user}
									displayCircularProgress={this.props.displayCircularProgress}
									hideCircularProgress={this.props.hideCircularProgress}
									/>
								</div>
				    })}
				</ul>
	    	</div>
		  )
	}
}

export default ProductResults

