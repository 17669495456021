import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";
import 'firebase/storage';

// For the default version
import algoliasearch from 'algoliasearch';

const client = algoliasearch('FQ0TYC19EB', '3f2f008cd040ba15c631ef735387d160');
const index = client.initIndex('users');

var firebaseConfig = {
	apiKey: "AIzaSyAZ0CA6pbhez06YlwQdWiWbSN07guOTuTo",
	authDomain: "tofa-c8c15.firebaseapp.com",
	projectId: "tofa-c8c15",
	storageBucket: "gs://tofa-c8c15.appspot.com",
	messagingSenderId: "1588110014",
	appId: "1:1588110014:web:1add248fdb9da7fb5e74ef",
	databaseUrl: "https://tofa-c8c15-default-rtdb.firebaseio.com/",
	measurementId: "G-LHPMXEQXD7"
};
firebase.initializeApp(firebaseConfig);


// SYNC REALTIME DATABASE WITH ALGOLIA INDEX
const usersRef = firebase.database().ref('/users');
usersRef.on('child_added', addOrUpdateIndexRecord);
usersRef.on('child_changed', addOrUpdateIndexRecord);
usersRef.on('child_removed', deleteIndexRecord);

console.log('firebase.js imported');
function addOrUpdateIndexRecord(user) {
  // Get Firebase object
  const record = user.val();

  console.log('addOrUpdateIndexRecord ', user);

  // Specify Algolia's objectID using the Firebase object key
  record.objectID = user.key;
  delete record.profile_picture;
  delete record.notifications;
  // Add or update object
  index
    .saveObject(record)
    .then(() => {
      console.log('Firebase object indexed in Algolia', record.objectID);
    })
    .catch(error => {
      console.error('Error when indexing user into Algolia', error);
      process.exit(1);
    });
}

function deleteIndexRecord({key}) {
  // Get Algolia's objectID from the Firebase object key
  const objectID = key;
  // Remove the object from Algolia
  index
    .deleteObject(objectID)
    .then(() => {
      console.log('Firebase object deleted from Algolia', objectID);
    })
    .catch(error => {
      console.error('Error when deleting contact from Algolia', error);
      process.exit(1);
    });
}

// CLOUD MESSAGING - FOR REALTIME NOTIFICATIONS
// TODO: This causes site to not load on mobile device safari or chrome
/*
const messaging = firebase.messaging();
const vapidKey = 'BBT_3bYLGRmXqcvDPC84bnunEhV7EU9AE7oTzcmYyXEIaC53-UMT4PusEY-7gfTIw1zQmD7884CmTcErQ_AXZ0g'
messaging.requestPermission().then(()=>{
  console.log('Have permission');

}).catch((err)=>{
  console.log('Do not have permission for push notifications: ', err);

})


// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
messaging.getToken({ vapidKey: vapidKey }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // ...
    console.log('currentToken: ', currentToken);
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

messaging.onMessage((payload) => {
  console.log('Message received. ', payload);
  // ...
});

//FOR TESTING CLOUD MSGS
curl -X POST -H "Authorization: key=AAAAAF6oor4:APA91bGH_L6w2Ry2BXzw0uGrkyAT5kau0yj_8XOFCWKP_nFP3z0OcD4snShpmWxPLdbnGcLngxIbvLRHqBOHdEL16z5DRCYkVIBXUkVUyTd9F1DqnDDx7GuyDX6fW0HQQ323OjTFu51s" \
   -H "Content-Type: application/json" \
   -d '{
  "data": {
    "notification": {
        "title": "Anshils title",
        "body": "This is an FCM Message",
        "icon": "/itwonders-web-logo.png",
    }
  },
  "to": "dQJGC0FPblSfmhOFuLXEHx:APA91bHJjrX69cb8WdM9QhDjgUTSMe9WQ9vk6vjNcGxv10Mpi-F8G9xkCYNmDL6skRWM2kqlhT1_eyIuQG2YUYbeGe0jV6DrHNJ4oKTXxKejFgp8aADawgjK-tSGKVb9w8xQZwahLfAO"
}' https://fcm.googleapis.com/fcm/send


*/

export {firebase, index}
